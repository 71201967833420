import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
//import { RouteComponentProps } from 'react-router';
import { useParams } from 'react-router-dom';
import { RouteComponentProps } from '../../../withRouter';

import { Button, Form } from 'reactstrap';

import OrganizationPipedrive from '../../../components/OrganizationPipedrive/OrganizationPipedrive';
import FooterFixed from '../../../components/UI/FooterFixed/FooterFixed';
import FormContainer from '../../../components/UI/FormContainer/FormContainer';
import FormFooter from '../../../components/UI/FormFooter/FormFooter';
import ListSpinner from '../../../components/UI/ListSpinner/ListSpinner';
import EUserRole from '../../../enums/user-roles';
import { IAppState, IOrganization, IPipedriveConnection, IUser } from '../../../interfaces';
import { getOrganizationControls } from '../../../shared/controls';
import { controlsToFormGroups, getFormData, hasAccess, initForm, validateInput } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import Users from '../../Users/Users';
import OrganizationPipedriveSwitch from '../../../components/OrganizationPipedrive/OrganizationPipedriveSwitch/OrganizationPipedriveSwitch';
import CustomDialog from '../../../components/UI/CustomDialog/CustomDialog';
import * as qs from 'query-string';

import OrganizationVisma from '../../../components/OrganizationVisma/OrganizationVisma';

import axios from '../../../axios';

import classes from "./Organization.module.scss";

interface IStateProps {
  currentUser: IUser | null;
  organization: IOrganization | null;
  error: string | null;
  loading: boolean;
  success: boolean;
  pipedriveConnection: IPipedriveConnection;
  pipedriveConnectionError: string | null;
  pipedriveConnectionLoading: boolean;
  disconnectPipedriveError: string | null;
  disconnectPipedriveLoading: boolean;
}

interface IDispatchProps {
  onGetOrganization: (id: string) => void;
  onGetUserOrganization: () => void;
  onUpdateOrganization: (organization: IOrganization) => void;
  onSaveOrganization: (organization: IOrganization) => void;
  onTestPipedrive: (id: string) => void;
  onDisconnectPipedrive: (id: string) => Promise<boolean>;
}

const defaultConfirmDialog = {
  isOpen: false,
  onOk: () => {},
  title: "",
  content: ""
};

interface IMatchProps {
  id: string;
}

interface IProps
  extends IStateProps,
    IDispatchProps,
    //RouteComponentProps<IMatchProps> {}
    RouteComponentProps {}

const Organization: React.FC<IProps> = props => {
  const {
    onGetOrganization,
    onGetUserOrganization,
    organization,
    currentUser,
    history,
    //match,
    onSaveOrganization,
    onUpdateOrganization,
    onTestPipedrive,
    loading,
    error,
    pipedriveConnection,
    pipedriveConnectionError,
    pipedriveConnectionLoading,
    onDisconnectPipedrive,
    disconnectPipedriveError,
    disconnectPipedriveLoading,
    location
  } = props;

  const params=useParams();

  const pipedriveSuccess = ((): string => {
    const _params = qs.parse(location.search);
    return _params.pipedriveSuccess as string;
  })();

  const [confirmDialog, setConfirmDialog] = useState(defaultConfirmDialog);
  const [init, setInit] = useState(true);

	const [integrations, setIntegrations]=useState(null);

  useEffect(() => {
    if (init && pipedriveSuccess) {
      openPipedriveInstallDialog();
      setInit(false);
    }
  }, [init, pipedriveSuccess]);

  const openPipedriveInstallDialog = () => {
    setConfirmDialog({
      onOk: () => {
        setConfirmDialog(defaultConfirmDialog);
      },
      isOpen: true,
      title: "Pipedrive",
      content: "Pipedrive connection was succesfully installed."
    });
  };

  const openPipedriveUninstallDialog = () => {
    setConfirmDialog({
      onOk: () => {
        setConfirmDialog(defaultConfirmDialog);
      },
      isOpen: true,
      title: "Pipedrive",
      content: "Pipedrive connection was succesfully uninstalled."
    });
  };

  const [state, setState] = useState({
    controls: getOrganizationControls(),
    formIsValid: false
  });

  useEffect(() => {
    //const { id } = match.params;
    const id = params.id;
    if (id && id !== "add" && hasAccess(currentUser, EUserRole.SUPERADMIN)) {
      onGetOrganization(id);
    } else if (id && id === "add") {
    } else {
      onGetUserOrganization();
    }
  //}, [currentUser, onGetOrganization, onGetUserOrganization, match.params]);
  }, [currentUser, onGetOrganization, onGetUserOrganization, params]);

  useEffect(() => {
    if (organization) {
      let controls = getOrganizationControls();

      if (hasAccess(currentUser, EUserRole.SUPERADMIN)) {
        controls = {
          ...controls,
          level: {
            elementType: "radio",
            elementConfig: {
              label: "Subscription level",
              type: "radio",
              options: [
                { value: "BASIC", displayValue: "Basic" },
                { value: "PREMIUM", displayValue: "Premium" },
                { value: "ENTERPRISE", displayValue: "Enterprise" }
              ],
              validation: {
                required: true
              },
              value: "",
              valid: false
            }
          },
          billingPolicy: {
            elementType: "radio",
            elementConfig: {
              label: "Billing policy",
              type: "radio",
              options: [
                { value: "NORMAL", displayValue: "Normal credit card billing after 14 day trial" },
                { value: "RESTRICTED", displayValue: "Force restricted use" },
                { value: "FREE", displayValue: "Free use, payment not required" }
              ],
              validation: {
                required: true
              },
              value: "",
              valid: false
            }
          },


          privateElements: {
            elementType: "select",
            elementConfig: {
              label: "Private Elements",
              placeholder: "Private Elements",
              isMulti: true,
              isClearable: true,
            },
            options:
            [
              {id:"PRIVATE", label:"Netvisor"},
              {id:"PRIVATE2", label:"PHM"},
            ],
            validation: {
              required: false
            },
            valueMapper: (x:any) => { return x.map((id:any)=>({id:id}))},
            valid: true,
            touched: false,
            value: ""
          },


          /*
          mgApiKey: {
            elementType: "input",
            elementConfig: {
              label: "Mailgun API Key",
              placeholder: "Mailgun API key",
              type: "text"
            },
            valid: true,
            touched: false,
            value: ""
          },
          mgApiUrl: {
            elementType: "input",
            elementConfig: {
              label: "Mailgun API Base URL",
              placeholder: "Mailgun API access point",
              type: "text"
            },
            valid: true,
            touched: false,
            value: ""
          }
          */
        };
      }
			
      const data = initForm(controls, organization);
      setState({
        controls: data.controls,
        formIsValid: data.formIsValid
			});

			setIntegrations(organization.integrations);
    }
  }, [currentUser, organization]);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    let value = event.target ? event.target.value : event;

    const validation = validateInput(state.controls, controlName, value);

    setState({
      controls: validation.controls,
      formIsValid: validation.formIsValid
    });
  };

  const submitHandler = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const id = organization ? organization.id : undefined;
		
		const formData = {...getFormData(state.controls),integrations};
		//const formData = getFormData(state.controls);

    if (id && id !== "add") {
      formData.id = id;
      await onUpdateOrganization(formData);
    } else {
      await onSaveOrganization(formData);
    }

    if (currentUser.role === EUserRole.SUPERADMIN) {
      history.push("/admin/organizations");
    } else {
      history.push("/");
    }
  };

  const pipedriveConnectionTestHandler = () => {
    //onTestPipedrive(match.params.id);
    onTestPipedrive(params.id);
  };

  const disconnectPipedriveHandler = async () => {
    if(!organization || !organization.id) return false;
    const success = await onDisconnectPipedrive(organization.id);
    return success;
  }
		
	const onUpdateIntegration = (name:string, o: IOrganization) => {
		setIntegrations({
			...integrations,
			[name]:{
				...integrations[name],
				...getFormData(o),
			},
		});
	}

  const [disabledIndex, setDisabledIndex] = useState<boolean>(false);
  const reIndex = (id: string) => {
    setDisabledIndex(true);
    axios.get("/tools/tasks/migrateoffer2?action=fixofferindex&id="+id)
      .then((resp)=>{
        console.log(resp)
      });
  }

  const downloadPDFs = () => {
    console.log("DOWNLOAD");
      const OFFERS_PATH='json/offers2';

      let tempOffers={};
  

        axios.get(OFFERS_PATH+`/list?listType=CONTRACTS&cuuid=${organization.id}`)
          .then((resp)=>{
            resp.data.filter((x:any)=>x.status==="CONFIRMED").forEach((x:any)=>{
              //console.log(x);
              axios.get(OFFERS_PATH+`/get?id=${x.id}`)
              .then((resp)=>{
                //console.log(resp.data); //.id, resp.data.resp.data.printLink);
                console.log([resp.data.id, resp.data.name, resp.data.customer.name, resp.data.userTemplate.name, resp.data.confirmDate].join(";"));

              })
              .catch((resp)=>{});
            });
            
/*            
            tempOffers={...tempOffers,[id]:resp.data}
            const next=loopOrganizations.pop();
            if(next) {
              setOffers(tempOffers)
              xloop(next)
            }
            else {
              setOffers(tempOffers);
            }
*/            
          })
          
          .catch((resp)=> {})
      //}
  

  }

  return (
    <React.Fragment>
      <CustomDialog
        loading={false}
        onOk={confirmDialog.onOk}
        okButtonText={"OK"}
        onCancel={() => setConfirmDialog(defaultConfirmDialog)}
        open={confirmDialog.isOpen}
        title={confirmDialog.title}
        isValid={true}
      >
        {confirmDialog.content}
      </CustomDialog>

      <FormContainer
        title="Manage organization"
        loading={loading}
        error={error}
      >
        <Form className={classes.Content}>
          {loading ? (
            <ListSpinner />
          ) : (
            controlsToFormGroups(state.controls, inputChangedHandler)
          )}
          <OrganizationPipedrive organization={organization} currentUser={currentUser} error={pipedriveConnectionError} loading={pipedriveConnectionLoading} connection={pipedriveConnection}  onTestConnection={pipedriveConnectionTestHandler}/>

          {organization ? (
            <OrganizationPipedriveSwitch
              organization={organization}
              disconnectPipedrive={disconnectPipedriveHandler}
              loading={disconnectPipedriveLoading}
              error={disconnectPipedriveError}
              currentUser={currentUser}
              openPipedriveUninstallDialog={openPipedriveUninstallDialog}
            />
          ) : null}

	    		{organization && hasAccess(currentUser, EUserRole.SUPERADMIN) ? (<>
						<br/>
	          <OrganizationVisma
              organization={organization}
							currentUser={currentUser}
							updateOrganization={onUpdateIntegration}
            />
          </>) : null}

  {/*organization && hasAccess(currentUser, EUserRole.SUPERADMIN) ? (<>
						<br/>
            {JSON.stringify(organization)}
          </>) : null*/}

        </Form>
        {hasAccess(currentUser, EUserRole.SUPERADMIN) &&
        organization &&
        organization.id ? (
          <div>
            <hr />
            {/*<Button onClick={downloadPDFs}>Contracts to PDF's</Button>*/}
            {/*<Button disabled={disabledIndex} onClick={()=>reIndex(organization.id)}>re-index</Button>*/}
            <hr />
            <Users {...props} organizationId={organization.id} />
          </div>
        ) : null}
      </FormContainer>
      <FooterFixed>
        <FormFooter
          saveText="Save organization"
          onSave={submitHandler}
          onDiscard={() => history.goBack()}
          isValid={state.formIsValid}
        />
      </FooterFixed>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    organization: state.organizations.organization,
    loading: state.organizations.loading,
    success: state.organizations.success,
    error: state.organizations.error,
    pipedriveConnection: state.organizations.pipedriveConnection,
    pipedriveConnectionLoading: state.organizations.pipedriveConnectionLoading,
    pipedriveConnectionError: state.organizations.pipedriveConnectionError,
    disconnectPipedriveLoading: state.organizations.disconnectPipedriveLoading,
    disconnectPipedriveError: state.organizations.disconnectPipedriveError,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetOrganization: id => dispatch(actions.getOrganization(id)),
    onGetUserOrganization: () => dispatch(actions.getUserOrganization()),
    onUpdateOrganization: organization =>
      dispatch(actions.updateOrganization(organization)),
    onSaveOrganization: organization =>
      dispatch(actions.saveOrganization(organization)),
    onTestPipedrive: id => dispatch(actions.testPipedrive(id)),
    onDisconnectPipedrive: id => dispatch(actions.disconnectPipedrive(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Organization);
