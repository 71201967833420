import React, {useState} from "react";
import ReactDOMServer from 'react-dom/server'

import {
  IBlockContent,
	//IBlockContentProducts,
	IBlockContentPrivate,
	IProduct,
	IOffer,
} from "../../../../../../../../interfaces";

import { ETaxTypes, EOfferStatus } from "../../../../../../../../enums";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from "@fortawesome/free-solid-svg-icons";

import { Container,Table, Row, Col, Input } from 'reactstrap';
import { InputGroup, InputGroupText } from 'reactstrap';

import { round, calcTotal } from '../../BlockContentProducts/functions';

import { isOfferSigned } from '../../../../../../../../shared/offer-utils';

import { readString } from "react-papaparse";

import { summa, summa2, bcparser } from "./functions";

import { writeFileXLSX } from "xlsx";


import classes from "./BlockContentProducts3Show.module.scss";

import { config } from '../../../../../../../../constants';

import axios from "axios";


var XLSX = require("xlsx");

interface IProps {
  blockContent: IBlockContent;
	showDummyContent?: boolean;
	onChange?: (blockContent: IBlockContent) => void;
	onBlur?: (blockContent: IBlockContent) => void;
	offer?: IOffer;
}

const BlockContentContentShow: React.FC<IProps> = props => {
	const blockContent = props.blockContent as IBlockContentPrivate;

	const [editQuantity, setEditQuantity]=useState<{[id:number]:boolean}>({});


	const data:any = bcparser(blockContent).section1;
	const data2:any = bcparser(blockContent).section2;
	const data3:any = bcparser(blockContent).section3;
	const data4:any = bcparser(blockContent).section4;
	const data5:any = bcparser(blockContent).section5;

	const setup:any = JSON.parse(blockContent.settings || "{}");
	const [alv, setAlv] = useState<number>(setup.alv || 24.0);

	const eur = (v:number) => {
		return (Math.round(v*100)/100).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}).replace(/,/g, " ").replace(/\./g, ",");
	}

  const round = (x:any) => {
  	if(isNaN(x)) return 1;
	  return (Math.round(parseFloat(x)*100)/100).toFixed(2);
  }

  const float = (x:any) => {
  	if(isNaN(x)) return 0;
  	return parseFloat(x);
  }


	const mymap = (x:any) => {
		 const [key,value] = x.split("=");
		 return [key.trim(),round(value)];
	}

	const mymap2 = (resp:any, x:any) => {
		 const [key,value] = x.split("=");
		 return {...resp, [key.trim()]:round(value)};
	}

	const sortnimi = (a:any, b:any) => {
		return a.nimi.toLowerCase()>b.nimi.toLowerCase()?1:-1;
	}

	const x:any = {header:false, skipEmptyLines:true};
	const d:any=readString(setup.asetukset,x);
	const tilat = d.data.reduce((resp:any, ob:any)=>{
		const _tila = {
			nimi: ob[0],
			standardiaika : float(ob[1]),
			kokokerroin: ob[2].split(";").map(mymap),
			likakerroin: ob[3].split(";").map(mymap),
			tuntihinta: float(ob[4]),
			lisatyot: ob[5].split(";").map(mymap).sort((a:any,b:any)=>a[0]>b[0]?1:-1),
			lisatyokerroin: ob[6].split(";").map(mymap),
			lisahinta: float(ob[7]),
			paketti:ob[6].split(";").reduce(mymap2,{}),

		}
		return [...resp,_tila];
	},[]).sort(sortnimi);

	const showInfoPage = () => {
		var win = window.open("", "Info", "");
		win.document.body.innerHTML = ReactDOMServer.renderToString(printInfo());

	}

	const printInfoCsv:any = () => {
		return "Tila;;Kpl;Kerrokset;Paketti;Tiheys;;Koko;;Lika;;h;€/h;€\n"

  	+ data.map((xselected:any, index:number) => {
			const tila = tilat[xselected.tila] || {
				nimi: "",
				standardiaika : 0,
				kokokerroin: [["",1]],
				likakerroin: [["",1]],
				tuntihinta: 0,
				lisatyot: [["",1]],
				lisatyokerroin: [["",1]],
				lisahinta: 0,
				kerrokset: 1,
				info1: "",
				info2: "",
			};

			const pakettiKerroin = (tila:any) => {
				const conv:{[id:string]:string} = {"A":"Täsmä","B":"Puhdas","C":"Loisto"};
				const p = conv[setup.paketti];
				if(tila.paketti && p in tila.paketti) {
					return tila.paketti[p];
				}
				return 1;
			}

		  const round = (x:any) => {
		  	if(isNaN(x)) return 1;
			  return (Math.round(parseFloat(x)*100)/100).toFixed(2);
		  }

			const hours = xselected.kpl 
				* xselected.kerros
				* setup.kuukausikertoimet[xselected.kuukausikerroin][1]
				* tila.kokokerroin[xselected.kokokerroin][1]
				* tila.likakerroin[xselected.likakerroin][1]
		  	* tila.standardiaika
				* pakettiKerroin(tila)
			;

			const row = [
		  		tilat[xselected.tila].nimi,
		  		tilat[xselected.tila].standardiaika,
					xselected.kpl,
					xselected.kerros,
					pakettiKerroin(tila),
 
		  		setup.kuukausikertoimet[xselected.kuukausikerroin][0],
				  setup.kuukausikertoimet[xselected.kuukausikerroin][1],

					tila.kokokerroin[xselected.kokokerroin][0],
					tila.kokokerroin[xselected.kokokerroin][1],
					tila.likakerroin[xselected.likakerroin][0],
					tila.likakerroin[xselected.likakerroin][1],


					hours,
					xselected.tuntihinta,

					xselected.tuntihinta*hours
					].join(";")

			return row;
		  	
  	}).join("\n")

  	+	"\n\nSiirtymäaika;€/h;Kerrat\n"+setup.siirtyma+";"+setup.siirtymaeurh+";"+kerroin
  	+"\n\nAlennus %\n"+data3.alennus;
		
	};

const download = () => {

	let filename = "Hintaerittely_vain_sisaiseen_kayttoon.xlsx";
  let xmlHttpRequest = new XMLHttpRequest();
  xmlHttpRequest.onreadystatechange = function() {
      var a;
      if (xmlHttpRequest.readyState === 4 && xmlHttpRequest.status === 200) {
          a = document.createElement('a');
          a.href = window.URL.createObjectURL(xmlHttpRequest.response);
          a.download = filename;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
      }
  };
  xmlHttpRequest.open("POST", config.CUSTOMIZED_URI+"/001/xlsx/");
  xmlHttpRequest.setRequestHeader("Content-Type", "application/json");
  xmlHttpRequest.responseType = 'blob';
  xmlHttpRequest.send(JSON.stringify(props.offer));

}

const exportToArray:any = () => {
	const header:any = [
		"Tila","",
		"Kpl",
		"Kerrokset",
		"Paketti",
		"Tiheys","",
		"Koko","",
		"Lika","",
		"h",
		"€/h",
		"€"
	];


  const rows = data.map((xselected:any, index:number) => {
			const tila = tilat[xselected.tila] || {
				nimi: "",
				standardiaika : 0,
				kokokerroin: [["",1]],
				likakerroin: [["",1]],
				tuntihinta: 0,
				lisatyot: [["",1]],
				lisatyokerroin: [["",1]],
				lisahinta: 0,
				kerrokset: 1,
				info1: "",
				info2: "",
			};

			const pakettiKerroin = (tila:any) => {
				const conv:{[id:string]:string} = {"A":"Täsmä","B":"Puhdas","C":"Loisto"};
				const p = conv[setup.paketti];
				if(tila.paketti && p in tila.paketti) {
					return tila.paketti[p];
				}
				return 1;
			}

		  const round = (x:any) => {
		  	if(isNaN(x)) return 1;
			  return (Math.round(parseFloat(x)*100)/100).toFixed(2);
		  }

			const hours = xselected.kpl 
				* xselected.kerros
				* setup.kuukausikertoimet[xselected.kuukausikerroin][1]
				* tila.kokokerroin[xselected.kokokerroin][1]
				* tila.likakerroin[xselected.likakerroin][1]
		  	* tila.standardiaika
				* pakettiKerroin(tila)
			;

			const no = index+2;
			const row = [
		  		tilat[xselected.tila].nimi,
		  		tilat[xselected.tila].standardiaika,
					xselected.kpl,
					xselected.kerros,
					parseFloat(pakettiKerroin(tila)),
 
		  		setup.kuukausikertoimet[xselected.kuukausikerroin][0],
				  parseFloat(setup.kuukausikertoimet[xselected.kuukausikerroin][1]),

					tila.kokokerroin[xselected.kokokerroin][0],
					parseFloat(tila.kokokerroin[xselected.kokokerroin][1]),
					tila.likakerroin[xselected.likakerroin][0],
					parseFloat(tila.likakerroin[xselected.likakerroin][1]),


					{f:"C"+no+"*D"+no+"*G"+no+"*I"+no+"*K"+no+"*B"+no+"*E"+no},
					xselected.tuntihinta,

					{f:"L"+no+"*M"+no},
			];

			return row;
  	});

  	const rl = rows.length;
  	const tl = rl+6;
  	const loput = [
  		["","","","","","","","","","","",{f:"SUM(L2:L"+(rl+1)+")"},"",{f:"SUM(N2:N"+(rl+1)+")"}],
  		[],
  		[],
  		["Siirtymäaika","€/h","Kerrat","Yhteensä","+alennus"],
  		[parseFloat(setup.siirtyma),parseFloat(setup.siirtymaeurh),kerroin, {f:"A"+tl+"*B"+tl+"*C"+tl},{f:"D"+tl+"/((100-A"+(tl+4)+")/100)"}],
  		[],
  		[],
			["Alennus %", "Ale","Yhteensä"],
			[parseFloat(data3.alennus),{f:"(N"+(rl+2)+"+E"+tl+")*(A"+(tl+4)+"/100)"},{f:"N"+(rl+2)+"+E"+tl+"-B"+(tl+4)}]
  	];


		

		return [header,...rows,...loput];

}


const printInfo:any = () => {
	return <div style={{"margin":20}}>			

		<table>

			<tr>
				<th>Tila</th>
				<th></th>
				<th>Kpl</th>
				<th>Kerrokset</th>
				<th>Tiheys</th>
				<th></th>
				<th>Koko</th>
				<th></th>
				<th>Lika</th>
				<th></th>
				<th>h</th>
				<th>€/h</th>
				<th>€</th>

			</tr>


  	{data.map((xselected:any, index:number) => {
			const tila = tilat[xselected.tila] || {
				nimi: "",
				standardiaika : 0,
				kokokerroin: [["",1]],
				likakerroin: [["",1]],
				tuntihinta: 0,
				lisatyot: [["",1]],
				lisatyokerroin: [["",1]],
				lisahinta: 0,
				kerrokset: 1,
				info1: "",
				info2: "",
			};

			const pakettiKerroin = (tila:any) => {
				const conv:{[id:string]:string} = {"A":"Täsmä","B":"Puhdas","C":"Loisto"};
				const p = conv[setup.paketti];
				if(tila.paketti && p in tila.paketti) {
					return tila.paketti[p];
				}
				return 1;
			}

		  const round = (x:any) => {
		  	if(isNaN(x)) return 1;
			  return (Math.round(parseFloat(x)*100)/100).toFixed(2);
		  }

			const hours = xselected.kpl 
				* xselected.kerros
				* setup.kuukausikertoimet[xselected.kuukausikerroin][1]
				* tila.kokokerroin[xselected.kokokerroin][1]
				* tila.likakerroin[xselected.likakerroin][1]
		  	* tila.standardiaika
				* pakettiKerroin(tila)
			;


			return (<tr key={index}>
		  		<td>{tilat[xselected.tila].nimi}</td>
		  		<td>{tilat[xselected.tila].standardiaika}</td>
					<td>{xselected.kpl}</td>
					<td>{xselected.kerros}</td>

		  		<td>{setup.kuukausikertoimet[xselected.kuukausikerroin][0]} </td>
				  <td>{setup.kuukausikertoimet[xselected.kuukausikerroin][1]} </td>

					<td>{tila.kokokerroin[xselected.kokokerroin][0]} </td>
					<td>{tila.kokokerroin[xselected.kokokerroin][1]} </td>
					<td>{tila.likakerroin[xselected.likakerroin][0]}</td>
					<td>{tila.likakerroin[xselected.likakerroin][1]}</td>


					<th>{round(hours)}h</th>

					<th>* {xselected.tuntihinta}</th>

					<th>= {round(xselected.tuntihinta*hours)}€</th>

		  	</tr>
  	)})}
  	</table>
		</div>
	};

	const showPrintPage = () => {
		let filename = "palvelukuvaukset.pdf";
	  let xmlHttpRequest = new XMLHttpRequest();
	  
	  xmlHttpRequest.onreadystatechange = function() {
	      var a;
	      if (xmlHttpRequest.readyState === 4 && xmlHttpRequest.status === 200) {
	          a = document.createElement('a');
	          a.href = window.URL.createObjectURL(xmlHttpRequest.response);
	          a.download = filename;
	          a.style.display = 'none';
	          document.body.appendChild(a);
	          a.click();
	      }
	  };
	  
	  xmlHttpRequest.open("POST", config.CUSTOMIZED_URI+"/001/pdf/");
	  xmlHttpRequest.setRequestHeader("Content-Type", "application/json");
	  xmlHttpRequest.responseType = 'blob';
	  xmlHttpRequest.send(JSON.stringify(props.offer));

	}

	const data4sort = (akey:string,bkey:string) => {
		const a = data4[akey];
		const b = data4[bkey];

		return a.order<b.order?1:-1;
	}

	const data4filter = (akey:string) => {
		const a = data4[akey];
		if(a.order!==undefined) return true;
		return false;
	}

	const printData:any = () => {
		let lineno = 0;

		return <html>
			<head>
				<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap"/>
			</head>
			<body><div style={{"margin":20,"fontFamily":"Open Sans"}}>			
				<div>
					<div style={{"float":"left"}}><img style={{"width":150,"marginRight":100,"marginBottom":40}} src="https://www.phmaski.fi/wp-content/uploads/2024/01/phm-aski-logo-slogan-1536x1486.png"/></div>
					<div style={{"float":"left"}}>
						<h3>{props.offer.customer.name}</h3>
						{props.offer.customer.businessID}<br/>
						{props.offer.customer.address}<br/>
						{props.offer.customer.zip} {props.offer.customer.city}<br/>
						{props.offer.customer.phone}<br/>
						{props.offer.customer.email}<br/>

					</div>
					<div style={{"float":"right"}}>{props.offer.updated}</div>
				</div>
				<br style={{"clear":"both"}}/>

				<h3>Ylläpitosiivouksen palvelukuvaukset</h3>

				<table style={{"width":"100%","fontSize":"0.7em","border":"solid 1px #f5f5f5","borderSpacing":0}}>
					<thead>
					<tr>
						<td style={{"whiteSpace":"nowrap"}}>ST</td>
						<td style={{"whiteSpace":"nowrap"}}>TILA</td>
						<td style={{"whiteSpace":"nowrap"}}>KPL</td>
						<td style={{"whiteSpace":"nowrap"}}>SIIVOUS</td>
						<td style={{"whiteSpace":"nowrap"}}>TYÖTEHTÄVÄ</td>
						<td style={{"whiteSpace":"nowrap"}}>HUOM!</td>
					</tr>
					</thead>
					<tbody>

				{Object.keys(data4).filter(data4filter).sort(data4sort).map((key:string)=>{
					
					const _data = data4[key];
					
					const ret = _data.rows.map((row:any, index:number)=> {
						if(_data.lkm > 0) {
							lineno++;
							return (<tr style={(lineno)%2 !== 0?{"background":"#f5f5f5"}:{}}>
								{index === 0 ? <td style={{"whiteSpace":"nowrap","padding":5}}>{_data.st}</td>: <td></td>}
								{index === 0 ? <td style={{"whiteSpace":"nowrap","padding":5}}><b>{key}</b></td>: <td></td>}
								{index === 0 ? <td style={{"whiteSpace":"nowrap","padding":5}}>{_data.lkm}</td>: <td></td>}
								<td style={{"whiteSpace":"nowrap","padding":5}}>{row[0].toLowerCase() === "siivoustiheys"?<>{_data.tiheys}</>:<>{row[0]}</>}</td>
								<td style={{"whiteSpace":"nowrap","padding":5}}>{row[1]}</td>
								<td>{row[2]}</td>
							</tr>)
						}
					});

					return ret;
				})}
					</tbody>
				</table>		


			</div></body>
		</html>;
}


	const kerroin = ( (setup.siirtymakerroin !== null && setup.siirtymakerroin > -1) && (setup.kuukausikertoimet.length>0) )?setup.kuukausikertoimet[setup.siirtymakerroin][1]:0;
	const siirtyma = data3.alennus === 0?setup.siirtyma * setup.siirtymaeurh * kerroin:(setup.siirtyma * setup.siirtymaeurh * kerroin)/((100-data3.alennus)/100);

	const summaf = (data3.alennus===0) || data3.showalennus?summa(setup,data,tilat)+siirtyma:(summa(setup,data,tilat)+siirtyma)*((100-data3.alennus)/100);
	const summa2f = (data3.alennus===0) || data3.showalennus?summa2(data2,tilat):(summa2(data2,tilat))*((100-data3.alennus)/100);

	return (<div>

		<div>
		{setup.paketti == "A" && <>Suosittelemme taloyhtiölle palvelupaketiksi <b>PHM Täsmä</b>ä. Tämä tarjous on laskettu kyseisellä palvelupaketilla. </>}
		{setup.paketti == "B" && <>Suosittelemme taloyhtiölle palvelupaketiksi <b>PHM Puhdas</b>ta. Tämä tarjous on laskettu kyseisellä palvelupaketilla. </>}
		{setup.paketti == "C" && <>Suosittelemme taloyhtiölle palvelupaketiksi <b>PHM Loisto</b>a. Tämä tarjous on laskettu kyseisellä palvelupaketilla. </>}
		
		<a onClick={showPrintPage} style={{"cursor":"pointer"}}>Tarkemmat palvelukuvaukset saa auki tästä linkistä.</a>
		</div>
		
		<div>
		<br/>

		{setup.tarjous === "A" ? <>
		TÄMÄ EI OLE KÄYTÖSSÄ
		<Row>
			<Col>Ylläpitosiivous</Col>
			<Col className="text-right">{round(summaf)} € Alv 0%</Col>
			<Col className="text-right">{round(summaf*(alv/100+1))} € Alv {alv}%</Col>
		</Row>

		<Row>
			<Col>Valitut lisäpalvelut</Col>
			<Col className="text-right">{round(summa2f/12)} € Alv 0%</Col>
			<Col className="text-right">{round(summa2f/12*(alv/100+1))} € Alv {alv}%</Col>
		</Row>

		<br/>
		
		<Row>
			<Col>Kuukausihinta koko paketille</Col>
			<Col className="text-right">{round(summaf+summa2f/12)} € Alv 0%</Col>
			<Col className="text-right">{round((summaf+summa2f/12)*(alv/100+1))} € Alv {alv}%</Col>
		</Row>

		{((data3.alennus > 0) && data3.showalennus) && <>
		<br/>
		<Row>
			<Col>Alennus -{data3.alennus}%</Col>
			<Col className="text-right">{-round( (summaf+summa2f/12)*((data3.alennus)/100) )} € Alv 0%</Col>
			<Col className="text-right">{-round( ((summaf+summa2f/12)*(alv/100+1))*((data3.alennus)/100) )} € Alv {alv}%</Col>
		</Row>
		<Row>
			<Col className={classes.bold}>Yhteensä</Col>
			<Col className={classes.bold+" text-right"}>{round( (summaf+summa2f/12)*((100-data3.alennus)/100) )} € Alv 0%</Col>
			<Col className={classes.bold+" text-right"}>{round( ((summaf+summa2f/12)*(alv/100+1))*((100-data3.alennus)/100) )} € Alv {alv}%</Col>
		</Row>

		</>}

		</>
		:<>
		<Row>
			<Col>Ylläpitosiivous €/kk</Col>
			<Col className="text-right">{round(summaf)} € Alv 0%</Col>
			<Col className="text-right">{round(summaf*(alv/100+1))} € Alv {alv}%</Col>
		</Row>

		{((data3.alennus > 0) && data3.showalennus) && <>
		<br/>
		<Row>
			<Col>Alennus -{data3.alennus}%</Col>
			<Col className="text-right">{-round( (summaf)*((data3.alennus)/100) )} € Alv 0%</Col>
			<Col className="text-right">{-round( ((summaf)*(alv/100+1))*((data3.alennus)/100) )} € Alv {alv}%</Col>
		</Row>
		<Row>
			<Col className={classes.bold}>Yhteensä</Col>
			<Col className={classes.bold+" text-right"}>{round( (summaf)*((100-data3.alennus)/100) )} € Alv 0%</Col>
			<Col className={classes.bold+" text-right"}>{round( ((summaf)*(alv/100+1))*((100-data3.alennus)/100) )} € Alv {alv}%</Col>
		</Row>
		</>}

		
		{data2.length>0 && <>
			<br/>
			Lisäpalvelut erillisveloituksena<br/><br/>
			
			{data2.map((ob:any)=>(
				<Row>
					<Col style={{"whiteSpace":"pre"}}>{ob.tyo}</Col>
					<Col className="text-right">{round(ob.hinta)} {ob.kpl} Alv 0%</Col>
					<Col className="text-right">{round(ob.hinta*(alv/100+1))} {ob.kpl} Alv {alv}%</Col>
					
					
				</Row>))}
		</>}
		{/*
		<Row>
			<Col>€/kerta</Col>
			<Col className="text-right">{round(summa2f)} € Alv 0%</Col>
			<Col className="text-right">{round(summa2f*(alv/100+1))} € Alv {alv}%</Col>
		</Row>

		{((data3.alennus > 0) && data3.showalennus) && <>
		<br/>
		<Row>
			<Col>Alennus -{data3.alennus}%</Col>
			<Col className="text-right">{-round( (summa2f)*((data3.alennus)/100) )} € Alv 0%</Col>
			<Col className="text-right">{-round( ((summa2f)*(alv/100+1))*((data3.alennus)/100) )} € Alv {alv}%</Col>
		</Row>
		<Row>
			<Col className={classes.bold}>Yhteensä</Col>
			<Col className={classes.bold+" text-right"}>{round( (summa2f)*((100-data3.alennus)/100) )} € Alv 0%</Col>
			<Col className={classes.bold+" text-right"}>{round( ((summa2f)*(alv/100+1))*((100-data3.alennus)/100) )} € Alv {alv}%</Col>
		</Row>
		</>}
		*/}

		</>}
		</div>
		<br/>
		<div className={classes.info}>{data3.info}</div>
		<br/>
		<div>
		

		{props.offer && props.offer.organization.integrations !== undefined && <>
			{/*<div><a onClick={showInfoPage} style={{"cursor":"pointer"}}>Tarkemman hintaerittelyn saat ladattua tästä linkistä (tämä linkki ei näy kuin kirjautuneille käyttäjille)</a></div>*/}
  		<div><a onClick={download}  style={{"cursor":"pointer"}}>Tarkemman hintaerittelyn saat ladattua tästä linkistä (tämä linkki ei näy kuin kirjautuneille käyttäjille)</a></div>

		</>}

		</div>

	</div>)};

export default BlockContentContentShow;
