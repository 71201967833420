import { ESigner } from './../enums/index';
import { EBlockEditingStyle } from './../sections/default/containers/Blocks/Block/Block';
import { EDragArea } from '../enums/drag';
import {
  EAuditLogAction,
  EContentType,
  ECurrency,
  ELanguage,
  EMessageStatus,
  EOfferStatus,
  EOrganizationLevel,
  EPaymentMethodType,
  ESubscriptionInterval,
  ESubscriptionStatus,
  EUserTemplateBlockType,
  EVideoSource,
  EVisualType,
	ETaxTypes,
  ESignerType,
} from './../enums';
import { EUserRole } from './../enums/user-roles';
import { IDraggable } from './component';



export interface IUser {
  id: string;
  legacyId?: string;
  email: string;
  name: string;
  role: EUserRole;
  roleString: string;
  phoneNumber: string;
  allowLogin: boolean;
  organizationId: string;
  firstName: string;
  lastName: string;
  imageURL: string;
  stripeCustomerId: string;
  stripePlanId: string;
  stripeSubscriptionId: string;
  createdUnixTime: number;
  isRestricted: boolean;
  isDeleted: boolean;
}

export interface IIntegrationsVismaSign {
	active: boolean;

	name: string;
	businessID: string;
	postalAddress: string;
	postalCode: string;
	municipality: string;

	identifier: string;
	firstName: string;
	lastName: string;
	email: string;

  organizationUuid: string;
  authorized: boolean;
}

export interface IIntegrationsProcountor {
	active: boolean;

	refreshToken: string;
  authorized: boolean;
  autoSend: boolean;
}

export interface IIntegrationsPipedrive {
	active: boolean;
	refreshToken: string;
  authorized: boolean;
  stageId: string;
  stageName: string;
  newStageId: string;
  newStageName: string;
  sendContract: boolean;
  setAsWon: boolean;
	accessToken: string;

  sentStageId: string;
  sentStageName: string;
  openedStageId: string;
  openedStageName: string;
  wonStageId: string;
  wonStageName: string;
  lostStageId: string;
  lostStageName: string;
}


export interface IIntegrationsHubspot {
	active: boolean;
	refreshToken: string;
  authorized: boolean;
  stageId: string;
  stageName: string;
  newStageId: string;
  newStageName: string;
  sendContract: boolean;
}

export interface IIntegrationsSevera {
  active: boolean;
  client_id: string;
  client_secret: string;
  refreshToken: string;
  authorized: boolean;
  stageId: string;
  stageName: string;
  sentStageId: string;
  sentStageName: string;
  openStageId: string;
  openStageName: string;
  newStageId: string;
  newStageName: string;
  sendContract: boolean;
}      
  
export interface IIntegrationsSalesforce {
  active: boolean;

  access_token: string;
  refresh_token: string;
  instance_url: string;
  user_id: string;
  organization_id: string;

  stageId: string;
  stageName: string;
  sentStageId: string;
  sentStageName: string;
  openStageId: string;
  openStageName: string;
  newStageId: string;
  newStageName: string;
  sendContract: boolean;
  sendProposal: boolean;
  sendContractToAccount: boolean;
  customFields: string;
}

export interface IIntegrationsDynamics {
  active: boolean;

  access_token: string;
  refresh_token: string;
  instance_url: string;
  user_id: string;
  organization_id: string;

  stageId: string;
  stageName: string;
  sentStageId: string;
  sentStageName: string;
  openStageId: string;
  openStageName: string;
  newStageId: string;
  newStageName: string;
  sendContract: boolean;
  sendProposal: boolean;
  sendContractToAccount: boolean;
  customFields: string;
}

export interface IIntegrationsGoogle {
  active: boolean;
}

export interface IIntegrationsNetvisor {
	active: boolean;
  autoSend: boolean;

	business_id: string;
  customer_id: string;
  private_key: string;
}

export interface IIntegrationsSsoSAML {
	active: boolean;
  idpEntityid: string;
  idpSingleSignOnServiceUrl: string;
  idpSingleLogoutServiceUrl: string;
  x509cert: string;

  allowLogin: boolean;
  loginUrl: string;
}



export interface IIntegrations {
	vismaSign: IIntegrationsVismaSign;
  procountor: IIntegrationsProcountor;
  hubspot: IIntegrationsHubspot;
  netvisor: IIntegrationsNetvisor;
  pipedrive: IIntegrationsPipedrive;
  ssoSAML: IIntegrationsSsoSAML;
  severa: IIntegrationsSevera;
  google: IIntegrationsGoogle;
  salesforce: IIntegrationsSalesforce;
  dynamics: IIntegrationsDynamics;
}

export interface IOrganization {
  id: string;
  name: string;
  level: EOrganizationLevel;
  businessID: string;
  streetAddress: string;
  zip: string;
  city: string;
  country: string;
  contactPerson: string;
  contactTitle: string;
  contactPhone: string;
  contactEmail: string;
  created: Date;
  mgApiKey: string;
  mgApiUrl: string;
  pipedriveConnected: boolean;
  pipedriveAuth: IPipedriveAuth;
  pipedriveAuthorizeUrl: string;

	integrations: IIntegrations;

  privateElements?: Array<{id:string}>;
}

export interface IPipedriveAuth {
  accessToken: string;
  refreshToken: string;
  expiresDate: string;
}

export interface IPipedriveConnection {
  success: boolean;
}

export interface IEmail {
  title: string;
  body: string;
  signature: string;
}

export interface IOfferComment {
  id: string;
  created: string;
  senderName: string;
  message: string;
  user: IUser;
}

export interface IOfferIntegrationData {
  type: string;
  name: string;
  value: string;
}

export interface IPipedriveDeal {
  id: string;
  currency?:string;
  customFields?:Array<ICustomField>;
}


export interface IOffer {
  id: string;
  customer: ICustomer;
  organization: IOrganization;
  status: EOfferStatus;
  author: IUser;
  created: string;
  updated: string;
  name: string;
  userTemplate: IUserTemplate;
  expirationDate: string;
  comments: Array<IOfferComment>;
  recipients: Array<string>;
  email: IEmail;
  isExpired: boolean;
  isExpiring: boolean;
  hasAttachments: boolean;
  history: Array<IOfferHistory>;
  viewsCount: number;
  archived: boolean;
  hash: string;
  link: string;
  printLink: string;
  downloadLink: string;
  lastSender: IUser;
  confirmDate: string;
  hideAuditLogs: boolean;
  authorName: string;
  customerName: string;
  customerSignature: string;
	strongAuthentication: string;
	signedPDF: string;
  hasSignature: boolean;

  offerIntegrationData: Array<IOfferIntegrationData>;

  pipedriveDeal?:IPipedriveDeal; //string; // pipedriveDeal ID
  
  //pipedriveProducts?:string;
  pipedriveProducts?:Array<IProduct>;

  customFields?:Array<ICustomField>;

  externalId?: string;

  offerCounter?: number;
}

export interface IOfferHistory {
  id: string;
  created: string;
  offerStatus: EOfferStatus;
  statusDetails: string;
  user: IUser;
  views: Array<IOfferHistoryView>;
  createdISO: string;
}

export interface IOfferHistoryView {
  id: string;
  viewDate: string;
  viewCount: number;
}

export interface ICustomer {
  id: string;
  name: string;
  address: string;
  zip?: string;
  city?: string;
  phone: string;
  email: string;
  contactPerson: string;
  businessID: string;
  language: ELanguage;
  pipedrivePersonId: string;
  pipedriveOrganizationId: string;
  isPipedrive: boolean;
}

export interface IBlock {
  id: string;
  name: string;
  editingStyle: EBlockEditingStyle;
  items: Array<IBlockItem>;
  content: Array<IBlockContent>;
}

export interface IBlockDraggable extends IBlock, IDraggable {}

export interface IBlockContent {
  id: string;
  name: string;
  contentType: EContentType;
  editOnOffer?: boolean;
  json?:string;
  settings?:string;
}

export interface IBlockContentContent extends IBlockContent {
  textType: string;
  content: string;
  title: string;
  linkText: string;
  contentLong: string;
}

export interface IBlockContentHTML extends IBlockContent {
  content: string;
  iframesrc: string;
  height: number;
  printable: boolean;
}

export interface IBlockContentTAG extends IBlockContent {
  tagType: string;
  properties: string;
}

export interface IBlockContentImage extends IBlockContent {
  width: string;
  height: string;
  images: Array<IAttachment>;
}

export interface IBlockContentVisual extends IBlockContent {
  visualType: EVisualType;
  images: Array<IAttachment>;
  pdfs: Array<IAttachment>;
  videoEmbed: string;
  videoSource: EVideoSource;
  width: string;
  height: string;

  firstPage: number;
  lastPage: number;
  viewType : string;
}

export interface IBlockContentPricing extends IBlockContent {
  pricingType: string;
  billingPeriod: string;
  content: string;
  price: number;
  priceOther: string;
  initialPayment: number;
  billingPeriodOther: string;
  terminationPeriod: number;
  estimatedAmount: number; // ei näytetä kenttää
  estimatedAmountMax: number; // ei näytetä kenttää
  estimatedAmountText: string;
  estimatedDeliveryDate: string;
}

export interface IBlockContentTeamMember extends IBlockContent {
  memberName: string;
  title: string;
  description: string;
  image: IAttachment;
  linkText: string;
}

export interface IBlockContentAttachment extends IBlockContent {
  attachments: Array<IAttachment>;
}

export interface IBlockContentSignature extends IBlockContent {
  signer: ESigner;
  signerTitle: string;
}

export interface IBlockItem {
  id: string;
  name: string;
  content: Array<IBlockContent>;
  editOnOffer?: boolean;
  //parentId?: string;
}

export enum EUserTemplateArea {
  header = "header",
  main = "main",
  footer = "footer"
}

export enum EBgType {
  IMAGE = "IMAGE",
  COLOR = "COLOR"
}

export interface IStyles {
  titleFont: string;
  paragraphFont: string;
  cssClassName: string;
  bgColor: string;
  marginVertical: string;
  marginHorizontal: string;
  paddingVertical: string;
  paddingHorizontal: string;
  bgImage: IAttachment;
  bgType: EBgType;
  titleColor: string;
  paragraphColor: string;
  linkColor: string;
}

export enum ESignType {
	VISMA="VISMA",
	SMS="SMS",
	NORMAL="NORMAL",
}

export interface ISettings {
	signType: ESignType;

  customerDetails_businessID?: string;
  customerDetails_address?: string;
  customerDetails_zip?: string;
  customerDetails_city?: string;

  customerDetails_name?: string;
  customerDetails_contactPerson?: string;
  customerDetails_phone?: string;
  customerDetails_email?: string;

  temporary?: boolean;

  expiryDays?: number;

  email_title?: string;
  email_body?: string;
  email_signature?: string;
}

export interface IUserTemplate {
  id: string;
  name: string;
  customColor: string;
  styles: IStyles;
  header: IUserTemplateArea;
  main: IUserTemplateArea;
  footer: IUserTemplateArea;
  templateBlocks: Array<IUserTemplateAreaBlock>;
	settings: ISettings;
  initialized?: boolean;
  userGroups: Array<string>;
}

export interface IUserTemplateEdit extends IUserTemplate {
  header: IUserTemplateAreaEdit;
  main: IUserTemplateAreaEdit;
  footer: IUserTemplateAreaEdit;
}

export interface IUserTemplateArea {
  name: string;
  styles: IStyles;
  blocks: Array<IUserTemplateAreaBlock>;
  type?: EDragArea;
}

export interface IUserTemplateAreaEdit extends IUserTemplateArea {
  blocks: Array<IUserTemplateBlockDraggable>;
}

export enum ELayoutType {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL"
}

export interface IUserTemplateAreaBlock {
  id: string;
  blockId: string;
  name: string;
  editingStyle: EBlockEditingStyle;
  showName: boolean;
  editableOnOffer: boolean;
  layoutType: ELayoutType;
  styles: IStyles;
  blockType: EUserTemplateBlockType;
  blockItems?: Array<IBlockItem>;
  offerBlockItems?: Array<IBlockItem>;
  content?: Array<IBlockContent>;
  isEmpty?: boolean;
}

export interface IUserTemplateBlockDraggable
  extends IUserTemplateAreaBlock,
    IDraggable {}

export interface IRegisterForm {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  name: string;
  companySize: string;
  industry: string;
  crm: string;
  teamSize: string;
}

export interface IPlan {
  plan_id: string;
  amount: number;
  currency: ECurrency;
  interval: ESubscriptionInterval;
  interval_count: number;
  product_id: string;
  product_name: string;
	active: string;
}

export interface IPaymentMethodDetail {
  created: Date;
  type: EPaymentMethodType;
  brand: string;
  lastDigits: string;
  validMonth: number;
  validYear: number;
  primary: boolean;
  stripePaymentMethodId: string;
}

export interface ISeats {
  activeUsers: number;
  availableSeats: number;
}

export interface IInvoice {
  id: string;
  hosted_invoice_url: string;
  invoice_pdf: string;
  number: string;
  receipt_number: string;
  status: string;
  paid: boolean;
  paid_at: Date;
  period_end: Date;
  period_start: Date;
  total: number;
  currency: ECurrency;
}

export interface ISubscriptionDetail {
  created: Date;
  updated: Date;
  subscriptionUser: IUser;
  interval: ESubscriptionInterval;
  currency: ECurrency;
  seats: number;
  status: ESubscriptionStatus;
  cancelDate: Date;
  cancelUser: IUser;
  price: number;
  stripeSubscriptionId: string;
  stripePlanId: string;
  stripeItemId: string;
}

export interface IMessage {
  id: string;
  created: Date;
  updated: Date;
  status: EMessageStatus;
  message: string;
  recipient: string;
  mailgunMessageId: string;
  isCustomer: boolean;
}

export interface IAttachment {
  id: string;
  serverName: string;
  clientName: string;
  contentType: string;
  size: number;
  hash: string;
  link: string;
  data?:any;
}

export interface ISearchOffer {
  id: string;
  name: string;
  customerName: string;
  customerContactPerson: string;
  updated: string;
}

export interface IAuditLog {
  id: string;
  created: string;
  user: IUser;
  action: EAuditLogAction;
  customDetails: string;
  ipAddress: string;
  browserName: string;
  operatingSystem: string;
  userAgent: string;
  country: string;
  region: string;
  city: string;
}

export interface IBlockContentFields extends IBlockContent {
	leftFields:Array<IFields>;
	rightFields: Array<IFields>;
}

export interface IFields {
  value: string;
  fontSize: number;
}

export interface IBlockContentProducts extends IBlockContent {
  products:Array<IProduct>;
	currency:string;
	tax?: ETaxTypes;

  showTotal?: boolean;
  color?: string;

	labels:{
		item:string,
  	description: string,
  	quantity: string,
  	unit: string,
  	unitPrice: string,
  	discount: string,
  	vat: string,
		total: string,
	};
}

export interface IProduct {
	item: string;
  description: string;
  quantity: number;
  unit: string;
  unitPrice: number;
  discount: number;
  vat: number;
	library?: boolean;
	optional?: boolean;
	optionalSelected?: boolean;
	quantityEditable?: boolean;

  category?: string;
  deleted?: boolean;

  order_nr?: number;
}

export interface ICustomField {
	apiKey: string;
  value: string;
}

export interface IBlockContentSignatures extends IBlockContent {
  signatures:Array<ISignature>;
}

export interface ISignature {
  name: string;
  email: string;
  phone: string;
  title: string;
  timestamp: Date;
  image: string;
  signerType: ESignerType;
  hash?: string;
}

export interface IUserGroup {
  id: string;
  name: string;
  members: Array<string>;
  leads: Array<string>;
}

export interface IBlockContentParties extends IBlockContent {
  isModified: boolean;
  
  leftName: string;
  leftId: string;
  leftAddress: string;
  leftContact: string;
  leftPhone: string;
  leftEmail: string;
  leftMore: string;

  rightName: string;
  rightId: string;
  rightAddress: string;
  rightContact: string;
  rightPhone: string;
  rightEmail: string;
  rightMore: string;
}

export interface IBlockContentPrivate extends IBlockContent {
  json?: string;
  settings?: string;
}

