import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import IconButton from "../../../../../../components/UI/IconButton/IconButton";
import { IBlockContent, IOrganization } from "../../../../../../interfaces";
import ContentAreaItem, {
  IContentAreaItem
} from "./ContentAreaItem/ContentAreaItem";
import ContentTypeDialog, {
  IContentTypeDialogData
} from "../ContentTypeDialog/ContentTypeDialog";
import BlockContentDialog from "../BlockContentDialog/BlockContentDialog";
import uuid from "uuid";
import { EUserTemplateBlockType } from "../../../../../../enums";

import classes from "./ContentArea.module.scss";

interface IProps {
  items: Array<IBlockContent>;
  setItems: (item: Array<IBlockContent>) => void;
  border?: boolean;
  addText: string;
  edit?: boolean;
  blockType: EUserTemplateBlockType;
  organization?: IOrganization;
}

const ContentArea: React.FC<IProps> = ({
  items,
  setItems,
  border,
  addText,
  edit,
  blockType,
  organization,
}) => {
  const [contentTypeIsOpen, setContentTypeIsOpen] = useState(false);
  const [blockContentIsOpen, setBlockContentIsOpen] = useState(false);
  const [blockContent, setBlockContent] = useState<IBlockContent>(null);

  const containerClasses = [classes.Container];

  if (border) {
    containerClasses.push(classes.Border);
  }

  const itemClickHandler = (item: IBlockContent) => {
    setBlockContent(item);
    setContentTypeIsOpen(true);
  };

  const moveItem = (dragItem: IContentAreaItem, toPosition: string) => {
    const newItems = [...items];

    const itemIndex = newItems.findIndex(item => item.id === dragItem.id);
    const index = newItems.findIndex(toItem => toItem.id === toPosition);
    const [item] = newItems.splice(itemIndex, 1);
    newItems.splice(index, 0, item);
    setItems(newItems);
  };

  const onDeleteContentType = (id: string) => {
    const newContentItems = [...items];
    const index = newContentItems.findIndex(
      contentItem => contentItem.id === id
    );
    if (index !== -1) {
      newContentItems.splice(index, 1);
    }
    setItems(newContentItems);
  };

  const onAddContentType = (data: IContentTypeDialogData) => {
    const newItems = [...items];
    if (data.id) {
      const index = newItems.findIndex(item => item.id === data.id);
      newItems[index] = data;
    } else {
      newItems.push({ id: uuid(), ...data });
    }
    setItems(newItems);
  };

  let onEditHandler: (content: IBlockContent) => void = null;
  if (edit) {
    onEditHandler = content => {
      setBlockContent(content);
      setBlockContentIsOpen(true);
    };
  }

  const onSaveEdit = (content: IBlockContent) => {
    const newItems = [...items];
    const index = newItems.findIndex(item => item.id === content.id);
    newItems[index] = { ...newItems[index], ...content };
    setItems(newItems);
  };

  return (
    <React.Fragment>
      <BlockContentDialog
        isOpen={blockContentIsOpen}
        setIsOpen={setBlockContentIsOpen}
        onSave={onSaveEdit}
        onDelete={onDeleteContentType}
        blockContent={blockContent}
      />

      <ContentTypeDialog
        isOpen={contentTypeIsOpen}
        setIsOpen={setContentTypeIsOpen}
        onSave={onAddContentType}
        onDelete={onDeleteContentType}
        blockContent={blockContent}
        blockType={blockType}
        organization={organization}
      />
      <div className={containerClasses.join(" ")}>
        {items &&
          items.map((item, index) => (
            <ContentAreaItem
              key={item.id}
              item={item}
              moveItem={moveItem}
              onClick={itemClickHandler}
              onEdit={onEditHandler}
            />
          ))}
        <IconButton
          style={{ marginTop: "1rem", fontWeight: 500 }}
          color="light"
          icon={faPlus}
          onClick={() => itemClickHandler(null)}
        >
          {addText}
        </IconButton>
      </div>
    </React.Fragment>
  );
};

export default ContentArea;
