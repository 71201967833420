import React, { useState, useContext } from "react";
import { Button } from "reactstrap";

import CustomDialog from "../../../../../../../components/UI/CustomDialog/CustomDialog";
import {
  IBlockContent,
  IBlockContentTAG
} from "../../../../../../../interfaces";
import { ETextType } from "../../ContentTypeDialog/ContentTypeItemContent/ContentTypeItemContent";
import OfferPreviewContext from "../../../../../containers/Offers/Offer/OfferPreviewContext";

import classes from "./BlockContentTAGShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent?: boolean;
  showOnlyInTemplateEditor?: boolean;
}

const BlockContentTAGShow: React.FC<IProps> = props => {
  const blockContent = props.blockContent as IBlockContentTAG;
  const showOnlyInTemplateEditor = props.showOnlyInTemplateEditor as boolean;

  const { customer, printable } = useContext(OfferPreviewContext);
  
  return (<span>
    {/*JSON.stringify(blockContent)*/}
    {blockContent.tagType === "LINE" && <hr/>}
    {blockContent.tagType === "PAGEBREAK" && <div className="pagebreakafter">
    {showOnlyInTemplateEditor && <div style={{textAlign:"center", //background: "repeating-linear-gradient(to right, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.5) 70px, rgba(255, 255, 255, 0) 70px, rgba(255, 255, 255, 0) 120px)"}}>
      background: "repeating-linear-gradient(to right, rgba(0, 0, 0, 0.5) 0px, rgba(0, 0, 0, 0.5) 10px, rgba(255, 255, 255, 0) 10px, rgba(255, 255, 255, 0) 20px)",
      backgroundSize: "100% 9%",
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "50%"}}>
    <span style={{background:"#ffffff"}}>P A G E &nbsp; B R E A K</span></div>}
    </div>}
    
  </span>);
  /*
  return (!printable || blockContent.printable) ? (
    <React.Fragment>
      <div>
        <div style={{"position":"relative","height":blockContent.height,"overflow":"hidden"}}>
          <iframe src={dynamicLink}
            frameBorder="0" allowFullScreen
            style={{"position":"absolute","top":0,"left":0,"width":"100%","height":"100%"}}></iframe>
        </div>
      </div>

    </React.Fragment>
  ) : null;
  */
};


export default BlockContentTAGShow;
